export default [
    {
        path: '/dashboard/notices/vacate/listing',
        name: 'dashboard-vacate-notices-listing',
        meta: { title: "Notice", authRequired: true },
        component: () => import('../notices/view/Notice.vue'),
    },
    {
        path: '/dashboard/notices/card:?',
        name: 'dashboard-notices-card',
        meta: { title: "Notice Card", authRequired: true },
        component: () => import('../notices/components/NoticeCard.vue'),
    },
]
