export default [
    {
        path: "/dashboard/properties/residential",
        name: "residentialProperties",
        meta: { title: "Residential Properties", authRequired: true },
        component: () => import("@/packages/property-management/property/view/Property.vue"),
    },
    {
        path: "/dashboard/properties/commercial",
        name: "commercialProperties",
        meta: { title: "Commercial Properties", authRequired: true },
        component: () => import("@/packages/property-management/property/components/CommercialProperties.vue"),
    },
    {
        path: "/dashboard/properties/card:?",
        name: "propertyCard",
        meta: { title: "Property Card", authRequired: true },
        component: () => import("@/packages/property-management/property/components/PropertyCard.vue"),
    }
]
