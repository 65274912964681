export default [
    {
        id: "report_main_menu",
        permission: "report_main_menu",
        disabled: false,
        title: "REPORT MANAGEMENT",
        order: 6,
        menus:[
            {
                id: "report_property_menu",
                permission: "report_property_menu",
                disabled: false,
                name: "Property Report",
                iconType:"boxicons",
                icon: "bx bxs-report",
                link: "/dashboard/statistics/property/reports",
                order:1,
                subMenu: [],
            },
        ]
    }
]
