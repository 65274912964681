export default [
    {
        path: '/dashboard/utilities/listing',
        name: 'dashboard-notices-listing',
        meta: { title: "Utilities", authRequired: true },
        component: () => import('../utilities/view/Utility.vue'),
    },
    {
        path: '/dashboard/utilities/card:?',
        name: 'dashboard-utilities-card',
        meta: { title: "Utilities Card", authRequired: true },
        component: () => import('../utilities/components/UtilityCard.vue'),
    },
]
