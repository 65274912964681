export default [
    {
        id: "application_menu",
        permission: "application_menu",
        disabled: false,
        title: "APPLICATIONS",
        order: 1,
        menus:[
            {
                id: "calendar_menu",
                permission: "calendar_menu",
                disabled: false,
                name: "Calendar",
                order: 1,
                iconType:"boxicons",
                icon: "bx bx-calendar-plus ",
                link: "/dashboard/calendar",
                subMenu: [],
            }
        ]
    },
]
