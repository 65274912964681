export default {
    namespaced: true,
    state: {

    },
    getters: {
      UtilityGetter: state=>setup=>state[setup],
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data
        }
    }
}
