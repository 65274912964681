export default [
    {
        id: "report_main_menu",
        permission: "report_main_menu",
        disabled: false,
        title: "REPORT MANAGEMENT",
        order: 6,
        menus:[
            {
                id: "report_general_ledger_menu",
                permission: "report_general_ledger_menu",
                disabled: false,
                name: "General Ledger",
                iconType:"remix",
                icon: "ri-line-chart-line ",
                link: "/dashboard/statistics/general-ledger",
                order:2,
                subMenu: [],
            },
        ]
    }
]
