export default {
    namespaced: true,
    state: {

    },
    getters: {
      PropertyGetter: state=>setup=>state[setup],
    },
    mutations: {

    }
}
