export default {
    namespaced: true,
    state: {

    },
    getters:{
        LeaseGetter: state=>setup=>state[setup],
    },
    mutations:{
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data
        }
    },
    actions:{

    }
}
