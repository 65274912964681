import PaymentGatewayStore from "@/packages/payment-management/paymentGateway/paymentGatewayStore";
import PaymentGatewayLinks from "@/packages/payment-management/paymentGateway/paymentGatewayLinks";
import PaymentGatewayRoutes from "@/packages/payment-management/paymentGateway/paymentGatewayRoutes";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("PaymentGateway",PaymentGatewayStore)
            //set links
            store.commit('Dashboard/MUTATE_LINKS',PaymentGatewayLinks)
        } if (router) {
            PaymentGatewayRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
