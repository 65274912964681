export default [
    {
        id: "user_main_menu",
        permission: "user_main_menu",
        disabled: false,
        title: "USER MANAGEMENT",
        order: 5,
        menus:[
            {
                id: "user_admin_menu",
                permission: "user_admin_menu",
                disabled: false,
                name: "Administrator",
                iconType:"remix",
                icon: " ri-group-fill",
                link: "/dashboard/user/administrator",
                order:1,
                subMenu: [
                    {
                        id: "user_admin_listing_menu",
                        permission: "user_admin_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/user/administrator/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "user_admin_card_menu",
                        permission: "user_admin_card_menu",
                        disabled: false,
                        name: "Administrator Card",
                        link: "/dashboard/user/administrator/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
