export default [
    {
        path: "/dashboard/payments/gateways/listing",
        name: "payment-gateway",
        meta: { title: "Payment Gateway", authRequired: true },
        component: () => import("@/packages/payment-management/paymentGateway/view/PaymentGateway.vue"),
    },
    {
        path: "/dashboard/payments/gateways/card:?",
        name: "payment-gateway-card",
        meta: { title: "Payment Gateway Card", authRequired: true },
        component: () => import("@/packages/payment-management/paymentGateway/components/PaymentGatewayCard.vue"),
    },
]
