import DashboardRoutes from "@/packages/dashboard/dashboardRoutes";
import DashboardStore from "@/packages/dashboard/dashboardStore";

export default {
    install(app, {router,store}) {
        if (router) {
            //not loading as required.
            router.addRoute(...DashboardRoutes);
        }
        if (store) {
            store.registerModule("Dashboard", DashboardStore);
            //
        }
    },
};

