export default [
    {
        path: "/dashboard/user/agency/listing",
        name: "Agency",
        meta: { title: "Agency", authRequired: true },
        component: () => import("@/packages/user-management/agency/view/Agency.vue"),
    },
    {
        path: "/dashboard/user/agency/card:?",
        name: "AgencyCard",
        meta: { title: "Agency Card", authRequired: true },
        component: () => import("@/packages/user-management/agency/components/AgencyCard.vue"),
    }
]
