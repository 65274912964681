import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import dashboard from "@/packages/dashboard";
import calendar from "@/packages/applications/calendar";
import property from "@/packages/property-management/property";
import lease from "@/packages/property-management/lease";
import tenant from "@/packages/user-management/tenant";
import landLord from "@/packages/user-management/landLord";
import agency from "@/packages/user-management/agency";
import administrator from "@/packages/user-management/administrator";
import payments from "@/packages/payment-management/payments";
import paymentGateway from "@/packages/payment-management/paymentGateway";
import notices from "@/packages/applications/notices";
import utilities from "@/packages/applications/utilities";
import invoice from "@/packages/payment-management/invoice";
import propertyReport from "@/packages/reports/property-report";
import journal from "@/packages/reports/journal";
import generalLedger from "@/packages/reports/general-ledger";

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    //packages
    .use(dashboard, {router, store})
    //applications
    .use(calendar, {router, store})
    .use(notices, {router, store})
    .use(utilities, {router, store})
    //property management
    .use(property,{router, store})
    .use(lease,{router, store})
    //user management
    .use(landLord,{router, store})
    .use(tenant,{router, store})
    .use(agency,{router, store})
    .use(administrator,{router, store})
    //payments
    .use(payments,{router, store})
    .use(paymentGateway,{router, store})
    .use(invoice,{router, store})
    //reports
    .use(propertyReport,{router, store})
    .use(journal,{router, store})
    .use(generalLedger,{router, store})
    //
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside).mount('#app');
