import UtilityStore from "@/packages/applications/utilities/utilityStore";
import UtilityLinks from "@/packages/applications/utilities/utilityLinks";
import UtilityRoutes from "@/packages/applications/utilities/utilityRoutes";

export default {
    install(app, {router,store}) {
        if (store) {
            store.registerModule("Utility",UtilityStore)
            store.commit('Dashboard/MUTATE_LINKS',UtilityLinks)
        }
        if (router) {
            UtilityRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
