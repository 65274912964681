import LandLordStore from "@/packages/user-management/landLord/landLordStore";
import LandLordLinks from "@/packages/user-management/landLord/landLordLinks";
import LandLordRoutes from "@/packages/user-management/landLord/landLordRoutes";

export default {
    install(app,{store,router}){
        if (store) {
            store.registerModule("LandLord",LandLordStore)
            store.commit("Dashboard/MUTATE_LINKS",LandLordLinks)
        }
        if (router) {
            LandLordRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
