export default [
    {
        id: "payment_menu",
        permission: "payment_menu",
        disabled: false,
        title: "PAYMENT MANAGEMENT",
        order: 4,
        menus:[
            {
                id: "payment_transactions_menu",
                permission: "payment_transactions_menu",
                disabled: false,
                name: "Payments",
                iconType:"remix",
                icon: "ri-secure-payment-line ",
                link: "/dashboard/payments/listing",
                order:1,
                subMenu: [
                    {
                        id: "payment_transactions_listing_menu",
                        permission: "payment_transactions_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/payments/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "payment_transactions_card_menu",
                        permission: "payment_transactions_card_menu",
                        disabled: false,
                        name: "Payment Card",
                        link: "/dashboard/payments/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
