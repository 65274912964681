import InvoiceStore from "@/packages/payment-management/invoice/invoiceStore";
import InvoiceLinks from "@/packages/payment-management/invoice/invoiceLinks";
import InvoiceRoutes from "@/packages/payment-management/invoice/invoiceRoutes";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("Invoice",InvoiceStore)
            //
            store.commit("Dashboard/MUTATE_LINKS",InvoiceLinks)
        }
        if (router) {
            InvoiceRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
