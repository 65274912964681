import TenantStore from "@/packages/user-management/tenant/tenantStore";
import TenantLinks from "@/packages/user-management/tenant/tenantLinks";
import TenantRoutes from "@/packages/user-management/tenant/tenantRoutes";

export default {
    install(app,{store,router}){
        if (store) {
            store.registerModule("Tenant",TenantStore)
            store.commit("Dashboard/MUTATE_LINKS",TenantLinks)
        }
        if (router) {
            TenantRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
