export default [
    {
        path: "/dashboard/statistics",
        name: "dashboard-projects",
        meta: {
            title: "Projects", authRequired: true,
        },
        component: () => import("@/packages/dashboard/view/Dashboard.vue"),
    },
]
