import LeaseStore from "@/packages/property-management/lease/leaseStore";
import LeaseLinks from "@/packages/property-management/lease/leaseLinks";
import LeaseRoutes from "@/packages/property-management/lease/leaseRoutes";

export default {
    install(app,{store,router}){
        if (store) {
            store.registerModule("Lease",LeaseStore)
            store.commit("Dashboard/MUTATE_LINKS",LeaseLinks)
        }
        if (router) {
            LeaseRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
