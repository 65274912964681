export default [
    {
        id: "application_menu",
        permission: "application_menu",
        disabled: false,
        title: "APPLICATIONS",
        order: 2,
        menus:[
            {
                id: "notice_menu",
                permission: "notice_menu",
                disabled: false,
                name: "Notices",
                iconType:"boxicons",
                icon: "bx bxs-note ",
                link: "/dashboard/notices/vacate/listing",
                order:2,
                subMenu: [
                    {
                        id: "vacate_notice_menu",
                        permission: "vacate_notice_menu",
                        disabled: false,
                        name: "Vacate Notice",
                        link: "/dashboard/notices/vacate/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "notice_card-menu",
                        permission: "notice_card_menu",
                        disabled: false,
                        name: "Notice Card",
                        link: "/dashboard/notices/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
