import PropertyReportStore from "@/packages/reports/property-report/propertyReportStore";
import propertyReportRoutes from "@/packages/reports/property-report/propertyReportRoutes";
import PropertyReportLinks from "@/packages/reports/property-report/propertyReportLinks";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("PropertyReport",PropertyReportStore)
            store.commit("Dashboard/MUTATE_LINKS",PropertyReportLinks)
        }
        if (router) {
            propertyReportRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
