export default [
    {
        id: "user_main_menu",
        permission: "user_main_menu",
        disabled: false,
        title: "USER MANAGEMENT",
        order: 5,
        menus:[
            {
                id: "user_tenant_menu",
                permission: "user_tenant_menu",
                disabled: false,
                name: "Tenant",
                iconType:"boxicons",
                icon: " bx bx-user-pin",
                link: "/dashboard/user/tenant",
                order:3,
                subMenu: [
                    {
                        id: "user_tenant_listing_menu",
                        permission: "user_tenant_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/user/tenant/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "user_tenant_listing_menu",
                        permission: "user_tenant_listing_menu",
                        disabled: false,
                        name: "Tenant Card",
                        link: "/dashboard/user/tenant/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
