import PropertyStore from "@/packages/property-management/property/propertyStore";
import PropertyRoutes from "@/packages/property-management/property/propertyRoutes";
import PropertyLinks from "@/packages/property-management/property/propertyLinks";

export default {
    install(app,{store,router}){
        if (store) {
            store.registerModule("Property",PropertyStore)
            store.commit("Dashboard/MUTATE_LINKS",PropertyLinks)
        }
        if (router) {
            PropertyRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
