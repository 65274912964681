export default {
    namespaced: true,
    state: {

    },
    getters: {
      JournalGetter: state=>setup=>state[setup],
    },
    mutations: {
    }
}
