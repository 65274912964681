import GeneralLedgerReportStore from "@/packages/reports/general-ledger/generalLedgerReportStore";
import GeneralLedgerReportLinks from "@/packages/reports/general-ledger/generalLedgerReportLinks";
import GeneralLedgerReportRoutes from "@/packages/reports/general-ledger/generalLedgerReportRoutes";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("GeneralLedger",GeneralLedgerReportStore)
            store.commit("Dashboard/MUTATE_LINKS",GeneralLedgerReportLinks)
        }
        if (router) {
            GeneralLedgerReportRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
