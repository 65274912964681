export default [
    {
        path: "/dashboard/user/tenant/listing",
        name: "Tenant",
        meta: { title: "Tenant", authRequired: true },
        component: () => import("@/packages/user-management/tenant/view/Tenant.vue"),
    },
    {
        path: "/dashboard/user/tenant/card:?",
        name: "TenantCard",
        meta: { title: "Tenant Card", authRequired: true },
        component: () => import("@/packages/user-management/tenant/components/TenantCard.vue"),
    }
]
