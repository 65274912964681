export default [
    {
        id: "property_main_menu",
        permission: "property_main_menu",
        disabled: false,
        title: "PROPERTY MANAGEMENT",
        order: 2,
        menus:[
            {
                id: "property_menu",
                permission: "property_menu",
                disabled: false,
                name: "Properties",
                iconType:"boxicons",
                icon: "bx bx-building-house",
                link: "/dashboard/properties",
                order:1,
                subMenu: [
                    {
                        id: "property_listing_menu",
                        permission: "property_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/properties",
                        order:1,
                        children: [
                            {
                                id: "property_listing_residential_menu",
                                permission: "property_listing_residential_menu",
                                disabled: false,
                                name: "Residential",
                                link: "/dashboard/properties/residential",
                                order:1,
                            },
                            {
                                id: "property_listing_commercial_menu",
                                permission: "property_listing_commercial_menu",
                                disabled: false,
                                name: "Commercial",
                                link: "/dashboard/properties/commercial",
                                order:2,
                            },
                        ],
                    },
                    {
                        name: "Property Card",
                        link: "/dashboard/properties/card",
                        disabled:false,
                        order:3,
                        children: [],
                    },
                ],
            },
        ]
    }
]
