export default [
    {
        id: "user_main_menu",
        permission: "user_main_menu",
        disabled: false,
        title: "USER MANAGEMENT",
        order: 5,
        menus:[
            {
                id: "user_landlord_menu",
                permission: "user_landlord_menu",
                disabled: false,
                name: "LandLord",
                iconType:"boxicons",
                icon: "bx bx-user-check",
                link: "/dashboard/user/landlord",
                order:2,
                subMenu: [
                    {
                        id: "user_landlord_listing_menu",
                        permission: "user_landlord_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/user/landlord/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "user_landlord_card_menu",
                        permission: "user_landlord_card_menu",
                        disabled: false,
                        name: "LandLord Card",
                        link: "/dashboard/user/landlord/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
