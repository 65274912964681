export default [
    {
        path: "/dashboard/payments/invoices/listing",
        name: "payments-invoices-listing",
        meta: { title: "Payments Invoice", authRequired: true },
        component: () => import("@/packages/payment-management/invoice/view/Invoice.vue"),
    },
    {
        path: "/dashboard/payments/invoices/card:?",
        name: "payments-invoice-card",
        meta: { title: "Payments Card", authRequired: true },
        component: () => import("@/packages/payment-management/invoice/components/InvoiceCard.vue"),
    },
]
