import _ from "lodash";
export default {
    namespaced: true,
    state: {
        links:[
            {
                id: "main_menu",
                permission: "main_menu",
                disabled: false,
                title: "MAIN MENU",
                order: 1,
                menus:[
                    {
                        id: "dashboard_menu",
                        permission: "dashboard_menu",
                        disabled: false,
                        name: "Dashboard",
                        order: 1,
                        iconType:"remix",
                        icon: "ri-dashboard-2-line",
                        link: "/dashboard/statistics",
                        subMenu: [],
                    }
                ]
            },
        ],
        loading: false,
    },
    getters:{
        DashboardGetter: state=>setup=>state[setup],
        //order by order
        DashboardGetterLinks: (state) => {
            return _.orderBy(state.links, 'order');
        },
    },
    mutations: {
        MUTATE: (state, payload) => {
            state[payload.state] = payload.data
        },
        MUTATE_LINKS: (state, payload) => {
            const titles = state.links.map(link => link.title)
            payload.forEach(item => {
                const index = titles.indexOf(item.title)
                if (index === -1) {
                    state.links.push(item)
                } else {
                    const menus = item.menus || []
                    state.links[index].menus.push(...menus)
                }
            })
        }
    },
    actions: {
        setLinks: ({commit}, payload) => {
            commit('MUTATE', {
                state: 'sidebarLinks',
                data: payload
            })
        }
    }
}
