export default [
    {
        id: "application_menu",
        permission: "application_menu",
        disabled: false,
        title: "APPLICATIONS",
        order: 2,
        menus:[
            {

                id: "utility_menu",
                permission: "utility_menu",
                disabled: false,
                name: "Utilities",
                iconType:"boxicons",
                icon: "bx bx-mobile-vibration  ",
                link: "/dashboard/utilities/listing",
                order:3,
                subMenu: [
                    {
                        id: "utility_listing_menu",
                        permission: "utility_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/utilities/listing",
                        order:3,
                        children: [],
                    },
                    {
                        id: "utility_card_-menu",
                        permission: "utility_listing_menu",
                        disabled: false,
                        name: "Utility Card",
                        link: "/dashboard/utilities/card",
                        order:3,
                        children: [],
                    },
                ],
            },
        ]
    }
]
