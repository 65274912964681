export default [
    {
        id: "user_main_menu",
        permission: "user_main_menu",
        disabled: false,
        title: "USER MANAGEMENT",
        order: 5,
        menus:[
            {
                id: "user_agency_menu",
                permission: "user_agency_menu",
                disabled: false,
                name: "Agency",
                iconType:"remix",
                icon: "ri-bank-fill ",
                link: "/dashboard/user/agency",
                order:4,
                subMenu: [
                    {
                        id: "user_agency_listing_menu",
                        permission: "user_agency_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/user/agency/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "user_agency_card_menu",
                        permission: "user_agency_card_menu",
                        disabled: false,
                        name: "Agency Card",
                        link: "/dashboard/user/agency/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
