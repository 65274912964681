export default [
    {
        id: "report_main_menu",
        permission: "report_main_menu",
        disabled: false,
        title: "REPORT MANAGEMENT",
        order: 6,
        menus:[
            {
                id: "report_journal_menu",
                permission: "report_journal_menu",
                disabled: false,
                name: "Journal Report",
                iconType:"remix",
                icon: "ri-pie-chart-fill ",
                link: "/dashboard/statistics/journal/reports",
                order:3,
                subMenu: [],
            },
        ]
    }
]
