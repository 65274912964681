import JournalReportStore from "@/packages/reports/journal/journalReportStore";
import JournalReportLinks from "@/packages/reports/journal/journalReportLinks";
import journalReportRoutes from "@/packages/reports/journal/journalReportRoutes";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("Journal",JournalReportStore)
            store.commit("Dashboard/MUTATE_LINKS",JournalReportLinks)
        }
        if (router) {
            journalReportRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
