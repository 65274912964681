import PaymentStore from "@/packages/payment-management/payments/paymentStore";
import PaymentLinks from "@/packages/payment-management/payments/paymentLinks";
import PaymentRoutes from "@/packages/payment-management/payments/paymentRoutes";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("Payment",PaymentStore)
            //set links
            store.commit('Dashboard/MUTATE_LINKS',PaymentLinks)
        } if (router) {
            PaymentRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
