export default [
    {
        path: "/dashboard/lease/listing",
        name: "Leases",
        meta: { title: "Leases", authRequired: true },
        component: () => import("@/packages/property-management/lease/view/Lease.vue"),
    },

    {
        path: "/dashboard/lease/card:?",
        name: "LeaseCard",
        meta: { title: "Lease Card", authRequired: true },
        component: () => import("@/packages/property-management/lease/components/LeaseCard.vue"),
    }
]
