export default [
    {
        path: "/dashboard/user/administrator/listing",
        name: "user-administrator-listing",
        meta: { title: "Administrator", authRequired: true },
        component: () => import("@/packages/user-management/administrator/view/Administrator.vue"),
    },
    {
        path: "/dashboard/user/administrator/card:?",
        name: "user-administrator-card",
        meta: { title: "Administrator Card", authRequired: true },
        component: () => import("@/packages/user-management/administrator/components/AdministratorCard.vue"),
    }
]
