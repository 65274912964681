import NoticeStore from "@/packages/applications/notices/noticeStore";
import noticeRoutes from "@/packages/applications/notices/noticeRoutes";
import noticeLinks from "@/packages/applications/notices/noticeLinks";

export default {
    install(app, {router,store}) {
        if (store) {
            store.registerModule("Notice",NoticeStore)
            store.commit('Dashboard/MUTATE_LINKS',noticeLinks)
        }
        if (router) {
            noticeRoutes.forEach(route => {
                router.addRoute(route)
            })
        }
    }
}
