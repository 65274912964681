export default [
    {
        path: "/dashboard/payments/listing",
        name: "payments",
        meta: { title: "Payments", authRequired: true },
        component: () => import("@/packages/payment-management/payments/view/Payment.vue"),
    },
    {
        path: "/dashboard/payments/card:?",
        name: "payments-card",
        meta: { title: "Payments Card", authRequired: true },
        component: () => import("@/packages/payment-management/payments/components/PaymentCard.vue"),
    },
]
