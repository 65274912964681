export default [
    {
        id: "property_main_menu",
        permission: "property_main_menu",
        disabled: false,
        title: "PROPERTY MANAGEMENT",
        order: 2,
        menus:[
            {
                id: "lease_menu",
                permission: "lease_menu",
                disabled: false,
                name: "Lease",
                iconType:"remix",
                icon: "ri-home-smile-2-line ",
                link: "/dashboard/lease",
                order:2,
                subMenu: [
                    {
                        id: "lease_listing_menu",
                        permission: "lease_listing_menu",
                        disabled: false,
                        name: "Listing",
                        link: "/dashboard/lease/listing",
                        order:1,
                        children: [],
                    },
                    {
                        id: "lease_card_menu",
                        permission: "lease_card_menu",
                        disabled: false,
                        name: "Lease Card",
                        link: "/dashboard/lease/card",
                        order:2,
                        children: [],
                    },
                ],
            },
        ]
    }
]
