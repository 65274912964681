import AdministratorStore from "@/packages/user-management/administrator/AdministratorStore";
import AdministratorLinks from "@/packages/user-management/administrator/AdministratorLinks";
import AdministratorRoutes from "@/packages/user-management/administrator/AdministratorRoutes";

export default {
    install(app,{store,router}){
        if (store) {
            store.registerModule("Administrator",AdministratorStore)
            store.commit("Dashboard/MUTATE_LINKS",AdministratorLinks)
        }
        if (router) {
            AdministratorRoutes.forEach((route) => {
                router.addRoute(route);
            });
        }
    }
}
