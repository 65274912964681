export default [
    {
        path: "/dashboard/user/landlord/listing",
        name: "user-landlord-listing",
        meta: { title: "LandLord", authRequired: true },
        component: () => import("@/packages/user-management/landLord/view/LandLord.vue"),
    },
    {
        path: "/dashboard/user/landlord/card:?",
        name: "user-landlord-card",
        meta: { title: "LandLord Card", authRequired: true },
        component: () => import("@/packages/user-management/landLord/components/LandLordCard.vue"),
    }
]
