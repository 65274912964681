import CalendarStore from "@/packages/applications/calendar/calendarStore";
import CalendarRoutes from "@/packages/applications/calendar/calendarRoutes";
import CalendarLinks from "@/packages/applications/calendar/calendarLinks";

export default {
    install(app,{store,router}) {
        if (store) {
            store.registerModule("Calendar",CalendarStore)
            //set links
            store.commit('Dashboard/MUTATE_LINKS',CalendarLinks)
        } if (router) {
            router.addRoute(...CalendarRoutes)
        }
    }
}
